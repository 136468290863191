<template>
    <div>
        <div v-if="!preNew && !preComplete && !preDelete">
            <v-btn :color="orderStatusColorHtml(config.order.status.new)" dark x-small @click="preNew = true">
                Новый
            </v-btn>

            <v-btn class="ml-3" :color="orderStatusColorHtml(config.order.status.complete)" dark x-small @click="preComplete = true">
                Доставлен
            </v-btn>

            <v-btn color="error" dark x-small class="ml-3" @click="preDelete = true">Удалить</v-btn>
        </div>

        <div class="mt-2 mb-2 d-flex justify-center flex-column" v-else>
            <b>{{ Array.isArray(order.orders) ? `${order.name.split('/')[0]} (+${(order.orders.length - 1)}) ${confirm}` : `${getOrderName(order)} ${confirm}` }}</b>

            <div class="ml-2">
                <v-btn text color="primary" @click="editOrder(order)">Да</v-btn>
                <v-btn text @click="cancel()">Нет</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import Orders from '../../../mixins/Orders.js'
    import Status from '../../../mixins/Status.js'
    import Notify from '../../../mixins/Notify.js'
    import config from '../../../config'
    export default {
        data() {
            return {
                config,
                currentItem: {},
                preNew: false,
                preComplete: false,
                preDelete: false,
            }
        },

        props: {
            couriers: {
                type: Array,
                default: null,
            },
            order: {
                type: Object,
                default: null,
            },
        },
        mixins: [Orders, Status, Notify],

        methods: {
            orderNew() {
                this.$store
                    .dispatch('order_reset', { orderId: this.currentItem.id, newStatus: true })
                    .then(res => {
                        if (res.data.success) {
                            console.log('order_reset as new ok')
                            this.notifyToast('Заказ успешно отменен и установлен как новый', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('order_reset as new fail')
                            this.notifyToast('Не удалось сбросить заказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('order_reset as new error', err.name, err.message)
                        this.notifyToast('Не удалось сбросить заказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            multiOrderNew() {
                this.$store
                    .dispatch('multi_order_reset', { orderId: this.currentItem.id, newStatus: true })
                    .then(res => {
                        if (res.data.success) {
                            console.log('multi_order_reset as new ok')
                            this.notifyToast('Мультизаказ успешно отменен и установлен как новый', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('multi_order_reset as new fail')
                            this.notifyToast('Не удалось сбросить мультизаказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('multi_order_reset as new error', err.name, err.message)
                        this.notifyToast('Не удалось сбросить мультизаказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            orderComplete() {
                this.$store
                    .dispatch('order_reset', { orderId: this.currentItem.id, newStatus: false })
                    .then(res => {
                        if (res.data.success) {
                            console.log('order_reset as complete ok')
                            this.notifyToast('Заказ успешно установлен как доставлен', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('order_reset as complete fail')
                            this.notifyToast('Не удалось сбросить заказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('order_reset as complete error', err.name, err.message)
                        this.notifyToast('Не удалось сбросить заказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            multiOrderComplete() {
                this.$store
                    .dispatch('multi_order_reset', { orderId: this.currentItem.id, newStatus: false })
                    .then(res => {
                        if (res.data.success) {
                            console.log('multi_order_reset as complete ok')
                            this.notifyToast('Мультизаказ успешно установлен как доставлен', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('multi_order_reset as complete fail')
                            this.notifyToast('Не удалось сбросить мультизаказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('multi_order_reset as complete error', err.name, err.message)
                        this.notifyToast('Не удалось сбросить мультизаказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            deleteOrder() {
                this.cancel()
                this.$store
                    .dispatch('order_delete1', this.currentItem.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                            this.notifyToast('Заказ успешно удален', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('order delete fail')
                            this.notifyToast('Не удалось удалить заказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('order_delete1 error', err.name, err.message)
                        this.notifyToast('Не удалось удалить заказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            deleteMultiOrder() {
                this.cancel()
                this.$store
                    .dispatch('multi_order_delete1', this.currentItem.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('multi_order delete ok')
                            this.notifyToast('Мультизаказ успешно удален', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('multi_order delete fail')
                            this.notifyToast('Не удалось удалить мультизаказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('multi_order_delete1 error', err.name, err.message)
                        this.notifyToast('Не удалось удалить мультизаказ', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            editOrder(item) {
                this.currentItem = item
                if (this.preNew) {
                    Array.isArray(item.orders) ? this.multiOrderNew() : this.orderNew()
                } else if (this.preComplete) {
                    Array.isArray(item.orders) ? this.multiOrderComplete() : this.orderComplete()
                } else if (this.preDelete) {
                    Array.isArray(item.orders) ? this.deleteMultiOrder() : this.deleteOrder()
                }
            },
            cancel() {
                this.preNew = false
                this.preComplete = false
                this.preDelete = false
            },
        },

        computed: {
            confirm() {
                let str = ''
                if (this.preNew) {
                    str = `отменить и сделать как "Новый"?`
                } else if (this.preComplete) {
                    str = `отменить и сделать "Доставлен"?`
                } else if (this.preDelete) {
                    str = `действительно удалить?`
                }
                return str
            },
        },
    }
</script>
