<template>
    <div class="logistic">
        <div class="map-wrapper">
            <manage-window v-if="!this.$store.state.isOrderWindowClose" @keyup.esc="closeWindow" :couriers="couriers" :orders="orders" :multiOrders='multiOrders' @update="dataUpdate" @resize="mapInvalidateSize" @open="mapInvalidateSize" @close="mapInvalidateSize"></manage-window>

            <manage-window-service @keyup.esc="closeWindow" :workers="workers" :orders="services" @update="dataUpdate"></manage-window-service>
            <div id="map"></div>
            <MapIcons :map="map" :data="iconsData"></MapIcons>
            <v-alert type="info" class="alert elevation-3" :value="alert" :dismissible="true">
                {{ $t('logistic.alert.no_couriers_found') }}
            </v-alert>
        </div>

        <div class="card-wrapper">
            <v-btn class="fab-menu" v-model="fab" color="blue darken-2" elevation="10" dark fab @click="fab = !fab">
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-menu </v-icon>
            </v-btn>

            <v-expansion-panels v-if="fab" v-model="panel" :disabled="disabled" multiple>
                <v-expansion-panel v-if="!userIsService">
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-run-fast</v-icon>
                        <span class="text-no-wrap" style="color: #666">В процессе доставки - {{ couriersProcess.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-panel-content">
                        <p v-if="!couriersProcess.length" class="item-courier-empty">нет курьеров</p>
                        <p v-for="(courier, i) of couriersProcess" :key="courier.id" class="item-courier" v-ripple @click="onClickUser(courier)">
                            {{ i + 1 }}. {{ displayName(courier) }}
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of courier.ordersActual" :key="order.id">
                                <template v-slot:activator="{ on, attrs }">
                                    <span 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="item-order d-inline-block" 
                                        :style="orderStatusStyle1(order)"
                                    >
                                        {{ getOrderName(order) }}
                                    </span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderFullText1(order)"></div>
                                </v-card>
                            </v-menu>
                            <!-- <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="multiOrder of courier.multiOrdersActual" :key="multiOrder.id">
                                <template v-slot:activator="{ on, attrs }">
                                    <span 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="item-order d-inline-block" 
                                        :style="orderStatusStyle1(multiOrder, true)"
                                    >
                                        {{ multiOrder.orders.map(e => getOrderName(e)).join(' / ') }}
                                    </span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="multiOrderFullText1(multiOrder)"></div>
                                </v-card>
                            </v-menu> -->
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="userIsOperator">
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-check</v-icon>
                        <span class="text-no-wrap" style="color: #666">Свободные курьеры - {{ couriersFree.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-panel-content">
                        <p v-if="!couriersFree.length" class="item-courier-empty">нет курьеров</p>
                        <p v-for="(courier, i) of couriersFree" :key="courier.id" class="item-courier" v-ripple @click="onClickUser(courier)">{{ i + 1 }}. {{ displayName(courier) }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="userIsService || (userIsOperator && this.$store.state.settings.services_enable)">
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-hammer-screwdriver</v-icon>
                        <span class="text-no-wrap" style="color: #666">Сервисмены - {{ workers.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="expansion-panel-content">
                        <p v-for="(worker, i) of workers" :key="worker.id" class="item-courier" v-ripple @click="onClickUser(worker)">{{ i + 1 }}. {{ displayName(worker) }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-information-outline</v-icon>
                        <span class="text-no-wrap" style="color: #666">Статусы заказов</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-chip v-for="status of orderStatuses" :key="status.text" :color="status.color" text-color="white" class="elevation-2">
                            <v-icon size="small" class="mr-1">{{ status.icon }}</v-icon>
                            {{ status.text }}
                        </v-chip>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="!userIsOperator || (userIsOperator && this.$store.state.settings.services_enable)">
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-account-wrench</v-icon>
                        <span class="text-no-wrap" style="color: #666">Статусы услуг</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-chip v-for="status of orderServiceStatuses" :key="status.text" :color="status.color" text-color="white" class="elevation-2">
                            <v-icon size="small" class="mr-1">{{ status.icon }}</v-icon>
                            {{ status.text }}
                        </v-chip>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-message-text-outline</v-icon>
                        <span class="text-no-wrap" style="color: #666">Маркеры на карте</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="mx-auto py-3">
                            <v-col cols="12" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.ordersComplete" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-check-bold</v-icon> Выполненные заказы</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" class="pa-1" v-if="!userIsOperator || (userIsOperator && this.$store.state.settings.services_enable)">
                                <v-checkbox v-model="$store.state.showMarkers.servicesComplete" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-check-bold</v-icon> Выполненные услуги</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.orders" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-inbox-multiple</v-icon> Заказы</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1" v-if="!userIsOperator || (userIsOperator && this.$store.state.settings.services_enable)">
                                <v-checkbox v-model="$store.state.showMarkers.services" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-hammer-screwdriver</v-icon> Услуги</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.points" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-home-group</v-icon> Точки</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col v-if="!userIsService" cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.couriers" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-car-multiple</v-icon> Курьеры</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1" v-if="!userIsOperator || (userIsOperator && this.$store.state.settings.services_enable)">
                                <v-checkbox v-model="$store.state.showMarkers.workers" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-account-hard-hat</v-icon> Сервисмены</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.arrows" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-arrow-expand</v-icon> Направление</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <!-- </v-speed-dial> -->
        </div>
    </div>
</template>

<script>
    import * as L from 'leaflet'
    // import polylineDecorator from 'leaflet-polylinedecorator'
    import 'leaflet-arrowheads'
    // import textPath from 'leaflet-textpath'
    import 'leaflet/dist/leaflet.css'
    const config = require('../../config')
    import moment from 'moment-timezone'

    moment.locale('ru')

    import MapIcons from './MapIcons'
    import ManageWindow from '../manage/ManageWindow.vue'
    import ManageWindowService from '../manageService/ManageWindowService.vue'

    // метод для копирования и слияния - полезный!
    // var obj = { a: 1 };
    // var copy = Object.assign({}, obj);

    /* leaflet icon */
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        mixins: [DateTime, Status, Orders],
        data() {
            return {
                map: null,
                sticky: false,
                alert: false,
                orders: [],
                services: [],
                points: [],
                couriers: [],
                couriersProcess: [],
                couriersFree: [],
                workers: [],
                workersProcess: [],
                workersFree: [],
                options: {},
                object_markers: [],
                object_markers_created: false,
                worker_markers_created: false,
                object_selected: 0,
                popup: null,
                timer: null,
                panel: [],
                disabled: false,
                marker_opened: null,
                marker_with_popup: null,
                marker_over: false,
                polylines: [],
                arrows: [],
                iconsData: [],
                iconsData1: [],
                iconsData2: [],
                iconsData3: [],

                fab: false,
                fab1: false,
                transition: 'slide-x-reverse-transition',
                d1: null,
                d2: null,
                userIsPoint: false,
                userIsOperator: false,
                userIsService: false,
                mynexParams: {},
                couriersCount: 0,
                workersCount: 0,
                couriersHash: 0,
                city: '',

                icons: [],
                multiOrders: [],
            }
        },
        computed: {
            activeFab() {
                switch (this.tabs) {
                    case 'one':
                        return { class: 'purple', icon: 'account_circle' }
                    case 'two':
                        return { class: 'red', icon: 'edit' }
                    case 'three':
                        return { class: 'green', icon: 'keyboard_arrow_up' }
                    default:
                        return {}
                }
            },
        },
        watch: {
            top(val) {
                this.bottom = !val
            },
            right(val) {
                this.left = !val
            },
            bottom(val) {
                this.top = !val
            },
            left(val) {
                this.right = !val
            },
        },

        components: {
            MapIcons,
            ManageWindow,
            ManageWindowService,
        },

        mounted() {
            console.log('!!! logistic mounted !!!')
            this.userIsOperator = this.$store.state.user.role === config.user.role.operator
            this.userIsPoint = this.$store.state.user.role === config.user.role.point
            this.userIsService = this.$store.state.user.role === config.user.role.service
            this.d1 = this.getDate1String()
            if (this.$store.state.user.name == 'freshmarket') {
                this.d2 = this.getDate2String(7)
            } else {
                this.d2 = this.getDate2String()
            }

            if (this.userIsPoint || this.userIsService) {
                this.city = this.userIsService?this.$store.state.user.service.city:this.$store.state.user.point.city
                this.$store
                    .dispatch('user_get', this.$store.state.user.operator_tid)
                    .then(res => {
                        if (res.data.success) {
                            this.mynexParams = {
                                uid: res.data.user.mynex_uid,
                                token: res.data.user.mynex_token,
                                series: res.data.user.mynex_series,
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.city = this.$store.state.settings.city
                this.mynexParams = {
                    uid: this.$store.state.user.mynex_uid,
                    token: this.$store.state.user.mynex_token,
                    series: this.$store.state.user.mynex_series,
                }
            }

            this.timer = setTimeout(() => {
                this.init()
            }, 10)
            // this.MapObjectHello()
        },
        destroyed() {
            console.log('!!! logistic destroyed !!!')
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.mapObjectsMarkersDelete(this.workers)
            this.mapObjectsMarkersDelete(this.couriers)
        },
        methods: {
            displayName(courier) {
                const firstName = courier.user?.firstName ?? ''
                const lastName = courier.user?.lastName ?? ''
                return this.userIsPoint ? firstName : firstName + ' ' + lastName
            },

            dataUpdate() {
                if (this.timer) {
                    clearTimeout(this.timer)
                    this.mapObjectsUpdate()
                }
            },

            mapInvalidateSize(s) {
                setTimeout(() => {
                    if (this.map) this.map.invalidateSize(false)
                    // if (this.map) this.map.invalidateSize(true)
                }, 100)
            },

            onClickUser(user) {
                console.log('onClickUser', user.full_name)
                this.map.setView([user.object.data.glat, user.object.data.glng], 16)
            },

            avatarIcon(courier) {
                let icon = this.icons.find(icon => icon.options.courierId === courier.id)

                if (!icon) {
                    icon = new L.DivIcon({
                        html: this.avatarIconContent(courier),
                        courierId: courier.id,
                    })
                    this.icons.push(icon)
                }

                return icon
            },

            avatarIconContent(courier) {
                let html = `<div class="avatar">
                    <img class="avatar-shadow" src="${require('../../assets/images/avatar-shadow-56.png')}">
                    <img class="avatar-icon" src="${config.api.delivery + 'user_avatar?tid=' + courier.user.telegramAccount.chatId}">
                `

                // const allOrdersTypes = [...courier.ordersActual, ...courier.multiOrdersActual]

                // for (let i = 0; i < allOrdersTypes.length; i++) {
                for (let i = 0; i < courier.ordersActual.length; i++) {
                    const order = courier.ordersActual[i]
                    // const order = allOrdersTypes[i]
                    html += `<div style="right: ${-20 - i * 10}px; background-color: ${this.orderStatusColorHtml1(order.status, Array.isArray(order.orders))}" class="avatar-status avatar-status-pos-${i}"></div>`
                }

                html += `</div>`

                return html
            },

            init() {
                // default Владивосток
                // [lat, lng]
                let center = [43.121284, 131.901917]
                let zoom = 12
                if (this.city) {
                    if (this.city.includes('Екатеринбург')) {
                        center = [56.839104, 60.60825]
                    } else if (this.city.includes('Хабаровск')) {
                        center = [48.482369, 135.084641]
                    } else if (this.city.includes('Новосибирск')) {
                        center = [55.026533, 82.955527]
                    } else if (this.city.includes('Ижевск')) {
                        center = [56.852677, 53.206896]
                    } else if (this.city.includes('Москва')) {
                        center = [55.755863, 37.617700]
                    } else if (this.city.includes('Благовещенск')) {
                        center = [50.287638, 127.533373]
                    } else if (this.city.includes('Краснодар')) {
                        center = [45.066115, 38.985679]
                    } else if (this.city.includes('Находка')) {
                        center = [42.828675, 132.807147]
                    } else if (this.city.includes('Уссурийск')) {
                        center = [43.792126, 132.002090]
                    } else if (this.city.includes('Южно-Сахалинск')) {
                        center = [46.963269, 142.743736]
                    } else if (this.city.includes('Череповец')) {
                        center = [59.128696, 37.916389]
                    }
                }

                this.map = L.map('map', {
                    center,
                    zoom,
                    zoomControl: true,
                    doubleClickZoom: true,
                    attributionControl: false,
                })
                L.tileLayer(config.map.url, {
                    // attributionControl: false,
                    // trackResize: false,
                    // tapTolerance: 5,
                    maxZoom: 20,
                    minZoom: 8,
                    retina: '@2x',
                    detectRetina: true,
                }).addTo(this.map)

                console.log('logistic init', this.map)

                // map.on('movestart', onMapMoveStart);
                // map.on('moveend', onMapMoveEnd);
                // map.on('dragend', onMapDragEnd);
                // map.on('dragstart', onMapDragStart);
                // map.on('viewreset', onMapViewReset);
                // map.on('zoomstart', onMapZoomStart);
                // map.on('zoomend', onMapZoomEnd);
                // map.on('click', onMapMouseClick);

                this.map.on('popupclose', this.onMapPopupClose)
                this.map.on('popupopen', this.onMapPopupOpen)

                this.map.on('click', e => {
                    console.log('⚡️ click map')
                    this.onMapMouseClick(e)
                })

                this.map.on('mousedown', e => {
                    console.log('⚡️ mouseup mousedown')
                })

                this.map.on('mouseup', e => {
                    console.log('⚡️ mouseup map')
                })

                this.map.on('move', e => {
                    console.log('⚡️ move map')
                })

                this.map.on('keydown', e => {
                    console.log('keydown', e.originalEvent.code)
                })

                this.markersUpdate()
            },

            markersUpdate() {
                this.$store.commit('showMarkers', this.$store.state.showMarkers)
                this.dataUpdate()
            },

            mapObjectsUpdate() {
                console.log('tick mapObjectsUpdate')

                let pointId = null
                // let point_id, service_id
                if (this.userIsPoint) {
                    pointId = this.$store.state.user.pointId
                // } else {
                    // point_id = 0
                }
                // if (this.userIsService) {
                //     service_id = this.$store.state.user.service_id
                // } else {
                //     service_id = 0
                // }

                // TODO: Восстановить сервисы

                // if (this.userIsService || (this.userIsOperator && this.$store.state.settings.services_enable)) {
                //     this.$store
                //         .dispatch('workers_get_all')
                //         .then(res => {
                //             if (res.data.success) {
                //                 this.workers = res.data.workers.filter(w => w.enable == 1)
                //                 if (this.userIsService) {
                //                     this.workers = this.workers.filter(w => w.service_id == service_id)
                //                 }
                //                 let options = {
                //                     date1: this.d1,
                //                     date2: this.d2,
                //                     deliveryTime: true,
                //                     service: true,
                //                 }
                //                 if (this.userIsPoint) {
                //                     options.point_id = point_id
                //                 } else {
                //                     options.service_id = service_id
                //                 }

                //                 this.$store.dispatch('orders_date', options).then(res => {
                //                     if (res.data.success) {
                //                         this.services = res.data.orders

                //                         this.iconsData3 = []
                //                         let services = []
                //                         if (this.$store.state.showMarkers.servicesComplete && this.$store.state.showMarkers.services) {
                //                             services = this.services
                //                         } else {
                //                             if (this.$store.state.showMarkers.servicesComplete) {
                //                                 services = this.services.filter(order => order.status == config.order.status.complete)
                //                             } else if (this.$store.state.showMarkers.services) {
                //                                 services = this.services.filter(order => order.status != config.order.status.complete)
                //                             }
                //                         }
                //                         for (let order of services) {
                //                             order.file_link = ''
                //                             if (order.media != null) {
                //                                 this.$store
                //                                     .dispatch('get_file', order.media.media_id)
                //                                     .then(res => {
                //                                         if (res.data.ok) {
                //                                         }
                //                                     })
                //                                     .catch(err => {
                //                                         console.log(err)
                //                                     })
                //                             }
                //                         }

                //                         for (let order of services) {
                //                             let coordinates = JSON.parse(order.coordinates)

                //                             if (coordinates) {
                //                                 const this_ = this
                //                                 let orderName = order.mode.substr(0, 2) + ' '
                //                                 if (order.name.length > 20) {
                //                                     orderName += order.name.substr(0, 20) + '...'
                //                                 } else {
                //                                     orderName += order.name
                //                                 }
                //                                 this.iconsData3.push({
                //                                     id: order.id,
                //                                     color: this.orderStatusColorHtml(order.status),
                //                                     content: orderName,
                //                                     lat: coordinates[1],
                //                                     lon: coordinates[0],
                //                                     options: {
                //                                         data: order,
                //                                     },
                //                                     tooltip: function () {
                //                                         return this_.orderFullText(this.options.data, true) + this.options.data.file_link
                //                                     },
                //                                 })
                //                             } else {
                //                                 console.log('order without coordinates', order)
                //                             }
                //                         }
                //                     }
                //                 })

                //                 if (this.mynexParams.uid) {
                //                     this.$http
                //                         .get(config.api.mynex + 'object/list', { params: this.mynexParams })
                //                         .then(res1 => {
                //                             this.workersProcess = this.workers.filter(worker => worker.act_ord > 0)
                //                             this.workersFree = this.workers.filter(worker => worker.act_ord == 0)

                //                             let objects = []
                //                             if (res1.data.response.success) {
                //                                 objects = res1.data.response.data
                //                             }

                //                             // default Владивосток
                //                             let parking_lng = 131.85208
                //                             let parking_lat = 43.113378
                //                             if (this.city) {
                //                                 if (this.city.includes('Екатеринбург')) {
                //                                     parking_lng = 60.24525
                //                                     parking_lat = 56.89629
                //                                 } else if (this.city.includes('Хабаровск')) {
                //                                     parking_lng = 134.968054
                //                                     parking_lat = 48.393594
                //                                 } else if (this.city.includes('Новосибирск')) {
                //                                     parking_lng = 82.862339
                //                                     parking_lat = 55.082863
                //                                 } else if (this.city.includes('Ижевск')) {
                //                                     parking_lng = 53.206896
                //                                     parking_lat = 56.852677
                //                                 } else if (this.city.includes('Москва')) {
                //                                     parking_lng = 37.617700
                //                                     parking_lat = 55.755863
                //                                 } else if (this.city.includes('Благовещенск')) {
                //                                     parking_lng = 127.533373
                //                                     parking_lat = 50.2876389
                //                                 } else if (this.city.includes('Краснодар')) {
                //                                     parking_lng = 38.985679
                //                                     parking_lat = 45.066115
                //                                 } else if (this.city.includes('Находка')) {
                //                                     parking_lng = 132.807147
                //                                     parking_lat = 42.828675
                //                                 } else if (this.city.includes('Уссурийск')) {
                //                                     parking_lng = 132.002090
                //                                     parking_lat = 43.792126
                //                                 } else if (this.city.includes('Южно-Сахалинск')) {
                //                                     parking_lng = 142.743736
                //                                     parking_lat = 46.963269
                //                                 }
                //                             }

                //                             let i = 0
                //                             for (let worker of this.workers) {
                //                                 let obj = {
                //                                     name: this.displayName(worker.full_name),
                //                                     ident: 0,
                //                                     oid: worker.tid,
                //                                     online: false,
                //                                     stime: 0,
                //                                     data: {
                //                                         mqua: 0,
                //                                         glat: parking_lat + i * 0.003,
                //                                         glng: parking_lng + i * 0.0015,
                //                                     },
                //                                 }

                //                                 for (let object of objects) {
                //                                     if (worker.mynex_ident == object.ident) {
                //                                         if (!object.data) {
                //                                             object.data = {}
                //                                         }
                //                                         let objCreated = this.object_markers.find(o=>o.options.user.id == worker.id)
                //                                         if (!object.data.mqua) object.data.mqua = 0
                //                                         if (!object.data.glat && !objCreated) object.data.glat = parking_lat + (i + 1) * 0.003
                //                                         if (!object.data.glng && !objCreated) object.data.glng = parking_lng + (i + 1) * 0.0015
                //                                         obj = object
                //                                         i--
                //                                         break
                //                                     }
                //                                 }
                //                                 worker.object = obj
                //                                 i++
                //                             }
                //                             if (this.$store.state.showMarkers.workers) {
                //                                 if (this.worker_markers_created && this.workersCount != this.workers.length) {
                //                                     this.worker_markers_created = false
                //                                     this.mapObjectsMarkersDelete(this.workers)
                //                                 }

                //                                 this.workersCount = this.workers.length

                //                                 if (!this.worker_markers_created) {
                //                                     this.worker_markers_created = true
                //                                     this.mapObjectsMarkersCreate('workers')
                //                                 }
                //                                 this.mapObjectsMarkersUpdate()
                //                             } else {
                //                                 if (this.worker_markers_created) {
                //                                     this.worker_markers_created = false
                //                                     this.mapObjectsMarkersDelete(this.workers)
                //                                 }
                //                             }
                //                         })
                //                         .catch(err => {
                //                             console.error(err)
                //                         })
                //                 }
                //             }
                //         })
                //         .catch(err => console.error(err))
                // }

                this.$store
                    .dispatch('logistics', pointId)
                    .then(res => {
                        if (res.data.success) {
                            const { couriers, orders, multiOrders } = res.data

                            const points = new Set()
                            this.iconsData1 = []
                            this.iconsData2 = []

                            // FIXME: в tooltip идут старые данные (не обновляется Текст при смене статусов в API)
                            for (let courier of couriers) {
                                for (let order of courier.ordersActual) {
                                    if (order.latitude && order.longitude) {
                                        const this_ = this
                                        const orderName = this.getOrderMode(order, true) + ' ' + this.getOrderName(order)

                                        this.iconsData1.push({
                                            id: order.id,
                                            color: this.orderStatusColorHtml1(order.status),
                                            content: orderName,
                                            lat: order.latitude,
                                            lon: order.longitude,
                                            options: {
                                                data: order,
                                            },
                                            tooltip: function () {
                                                return this_.orderFullText1(order, courier)
                                            },
                                        })
                                    } else {
                                        console.log('order without coordinates', order)
                                    }

                                    points.add(order.point)
                                }

                                // for (let i = 0; i < courier.multiOrdersActual.length; i += 1) {
                                //     const multiOrder = courier.multiOrdersActual[i]

                                //     if (multiOrder.orders[0].latitude && multiOrder.orders[0].longitude) {
                                //         const this_ = this

                                //         const multiOrderName = `${this.getOrderMode(multiOrder.orders[0], true)} ${multiOrder.orders[0].point.name ?? 'Мультизаказ'} +${(multiOrder.orders.length - 1)}`

                                //         this.iconsData1.push({
                                //             id: multiOrder.id,
                                //             color: '#FFF',
                                //             backgroundColor: this.orderStatusColorHtml1(multiOrder.status, true),
                                //             isCombined: true,
                                //             content: multiOrderName,
                                //             lat: multiOrder.orders[0].latitude,
                                //             lon: multiOrder.orders[0].longitude,
                                //             options: {
                                //                 data: multiOrder,
                                //             },
                                //             tooltip: function () {
                                //                 return this_.multiOrderFullText1(multiOrder, courier)
                                //             },
                                //         })

                                //     } else {
                                //         console.log('multiOrder without coordinates', multiOrder)
                                //     }

                                //     multiOrder.orders.forEach(e => points.add(e.point))
                                // }
                            }

                            // Не обработанные заказы
                            this.orders = orders

                            for (let order of this.orders) {
                                if (order.latitude && order.longitude) {
                                    const this_ = this
                                    const orderName = this.getOrderMode(order, true) + ' ' + this.getOrderName(order)

                                    this.iconsData1.push({
                                        id: order.id,
                                        color: this.orderStatusColorHtml1(order.status),
                                        content: orderName,
                                        lat: order.latitude,
                                        lon: order.longitude,
                                        options: {
                                            data: order,
                                        },
                                        tooltip: function () {
                                            return this_.orderFullText1(order)
                                        },
                                    })
                                } else {
                                    console.log('order without coordinates', order)
                                }

                                points.add(order.point)
                            }

                            // Не обработанные мультизаказы
                            this.multiOrders = multiOrders

                            for (let multiOrder of this.multiOrders) {
                                if (multiOrder.orders[0].latitude && multiOrder.orders[0].longitude) {
                                    const this_ = this

                                    const multiOrderName = `${this.getOrderMode(multiOrder.orders[0], true)} ${multiOrder.orders[0].point.name ?? 'Новый мультизаказ'} +${(multiOrder.orders.length - 1)}`

                                    this.iconsData1.push({
                                        id: multiOrder.id,
                                        color: '#FFF',
                                        backgroundColor: this.orderStatusColorHtml1(multiOrder.status, true),
                                        isCombined: true,
                                        content: multiOrderName,
                                        lat: multiOrder.orders[0].latitude,
                                        lon: multiOrder.orders[0].longitude,
                                        options: {
                                            data: multiOrder,
                                        },
                                        tooltip: function () {
                                            return this_.multiOrderFullText1(multiOrder)
                                        },
                                    })
                                } else {
                                    console.log('multiOrder without coordinates', multiOrder)
                                }

                                multiOrder.orders.forEach(e => points.add(e.point))

                            }

                            this.points = Array.from(points)
                            this.couriers = couriers

                            if (this.$store.state.showMarkers.points) {
                                for (let point of this.points) {
                                    this.iconsData2.push({
                                        id: point.id,
                                        color: '#233e6d',
                                        content: '🏪 ' + (point.name.length > 15 ? point.name.substr(0, 15) + '...' : point.name),
                                        lat: point.latitude + (0.5 - Math.random()) / 5000,
                                        lon: point.longitude + (0.5 - Math.random()) / 5000,
                                        options: {
                                            point,
                                        },
                                        tooltip: function () {
                                            return `
                                                🏪 <b>${this.options.point.name}</b><br>
                                                🏡 Адрес: <b>${this.options.point.address.replace('\n', ' ')}</b><br>
                                                
                                                `
                                        },
                                    })
                                }
                            }
                            this.iconsData = this.iconsData1.concat(this.iconsData2, this.iconsData3)
                        }

                        if (this.mynexParams.uid) {
                            this.$http
                                .get(config.api.mynex + 'object/list', { params: this.mynexParams })
                                .then(res1 => {
                                    // if (this.userIsPoint) {
                                    //     this.couriers = res.data.couriers.filter(
                                    //         courier =>
                                    //             courier.enable &&
                                    //             courier.orders.length &&
                                    //             courier.orders.find(o => o.pointId == this.$store.state.user.pointId)
                                    //     )
                                    // } else {
                                    //     this.couriers = res.data.couriers.filter(courier => courier.enable)
                                    // }

                                    this.couriersProcess = this.couriers.filter(courier => (courier.ordersActual.length) > 0)
                                    this.couriersFree = this.couriers.filter(courier => (courier.ordersActual.length) === 0)
                                    // this.couriersProcess = this.couriers.filter(courier => (courier.ordersActual.length + courier.multiOrdersActual.length) > 0)
                                    // this.couriersFree = this.couriers.filter(courier => (courier.ordersActual.length + courier.multiOrdersActual.length) === 0)

                                    let objects = []
                                    if (res1.data.response.success && res1.data.response.msg !== 'empty') {
                                        objects = res1.data.response.data
                                    }

                                    for (let polyline of this.polylines) {
                                        polyline.deleteArrowheads()
                                        this.map.removeLayer(polyline)
                                    }

                                    // default Владивосток
                                    let parking_lng = 131.85208
                                    let parking_lat = 43.113378
                                    if (this.city) {
                                        if (this.city.includes('Екатеринбург')) {
                                            parking_lng = 60.24525
                                            parking_lat = 56.89629
                                        } else if (this.city.includes('Хабаровск')) {
                                            parking_lng = 134.968054
                                            parking_lat = 48.393594
                                        } else if (this.city.includes('Новосибирск')) {
                                            parking_lng = 82.862339
                                            parking_lat = 55.082863
                                        } else if (this.city.includes('Ижевск')) {
                                            parking_lng = 53.206896
                                            parking_lat = 56.852677
                                        } else if (this.city.includes('Москва')) {
                                            parking_lng = 37.617700
                                            parking_lat = 55.755863
                                        } else if (this.city.includes('Благовещенск')) {
                                            parking_lng = 127.533373
                                            parking_lat = 50.2876389
                                        } else if (this.city.includes('Краснодар')) {
                                            parking_lng = 38.985679
                                            parking_lat = 45.066115
                                        } else if (this.city.includes('Находка')) {
                                            parking_lng = 132.807147
                                            parking_lat = 42.828675
                                        } else if (this.city.includes('Уссурийск')) {
                                            parking_lng = 132.002090
                                            parking_lat = 43.792126
                                        } else if (this.city.includes('Южно-Сахалинск')) {
                                            parking_lng = 142.743736
                                            parking_lat = 46.963269
                                        } else if (this.city.includes('Череповец')) {
                                            parking_lng = 37.916389
                                            parking_lat = 59.128696
                                        }
                                    }

                                    let i = 0
                                    for (let courier of this.couriers) {
                                        let obj = {
                                            name: this.displayName(courier),
                                            ident: 0,
                                            oid: 0,
                                            online: false,
                                            stime: 0,
                                            data: {
                                                mqua: 0,
                                                glat: parking_lat + i * 0.003,
                                                glng: parking_lng + i * 0.0015,
                                            },
                                        }
                                        // ! тут все конечно нужно переделывать - маркер курьера если уже существует, то не припарковывать его на дефолтное место, иначе иногда бывают прыжки туда-сюда. нужно обновлять координаты маркера курьера, если они есть, если нет - оставлять прошлые, далее привязывать линию направления к позиции маркера
                                        for (let object of objects) {
                                            // console.log('courier', i, courier.full_name, courier.mynex_ident)
                                            if (courier.mynexTracker?.ident == object.ident) {
                                                // console.log(i, courier.full_name, courier.mynex_ident, object.ident)
                                                if (!object.data) {
                                                    object.data = {}
                                                }
                                                let objCreated = this.object_markers.find(o=>o.options.courier.id == courier.id)
                                                if (!object.data.mqua) object.data.mqua = 0
                                                if (!object.data.glat && !objCreated) object.data.glat = parking_lat + (i + 1) * 0.003
                                                if (!object.data.glng && !objCreated) object.data.glng = parking_lng + (i + 1) * 0.0015

                                                obj = object
                                                i--
                                                break
                                            }
                                        }

                                        courier.object = obj

                                        if (this.$store.state.showMarkers.arrows) {
                                            // const today = new Date()

                                            for (let order of courier.ordersActual) {
                                                // const order_date = new Date(order.date)
                                                // if (!this.isSameDate({ date: order_date, compareDate: today, unit: 'day' })) continue
                                                // if (order.status == config.order.status.complete) continue
                                                // console.log('print order of courier', courier.full_name, order)
                                                let latlngs = []

                                                latlngs.push([courier.object.data.glat, courier.object.data.glng])

                                                if (order.status == this.OrderStatus.onWayCustomer || order.status == this.OrderStatus.passed) {
                                                    if (order.latitude && order.longitude) {
                                                        latlngs.push([order.latitude, order.longitude])
                                                    }
                                                } else if (order.status == this.OrderStatus.onWayPoint) {

                                                    if (order.point) {
                                                        if (order.point.latitude && order.point.longitude) {
                                                            latlngs.push([order.point.latitude, order.point.longitude])
                                                        }
                                                    }
                                                }

                                                let p = L.polyline(latlngs, {
                                                    color: '#444',
                                                    weight: 2,
                                                    dashArray: '10, 10',
                                                    dashOffset: '0',
                                                })
                                                p.arrowheads({
                                                    yawn: 25,
                                                    size: '20px',
                                                    fill: true,
                                                    stroke: false,
                                                })
                                                this.polylines.push(p)
                                                p.addTo(this.map)
                                                p.getArrowheads().addTo(this.map)
                                            }

                                            // for (let multiOrder of courier.multiOrdersActual) {
                                            //     let latlngs = []

                                            //     latlngs.push([courier.object.data.glat, courier.object.data.glng])

                                            //     if (multiOrder.status == this.OrderStatus.onWayCustomer || multiOrder.status == this.OrderStatus.passed) {
                                            //         if (multiOrder.orders[0].latitude && multiOrder.orders[0].longitude) {
                                            //             latlngs.push([multiOrder.orders[0].latitude, multiOrder.orders[0].longitude])
                                            //         }
                                            //     } else if (multiOrder.status == this.OrderStatus.onWayPoint) {
                                            //         const { orders, pointSequence } = multiOrder

                                            //         let nextOrder = null

                                            //         for (let i = 0; i < pointSequence.length; i += 1) {
                                            //             const pointId = pointSequence[i]

                                            //             const order = orders.find(e => e.point.id === pointId)

                                            //             if (typeof order === 'undefined') {
                                            //                 break
                                            //             }

                                            //             if (order.status === this.OrderStatus.onWayPoint) {
                                            //                 nextOrder = JSON.parse(JSON.stringify(order))

                                            //                 break
                                            //             }
                                            //         }

                                            //         if (nextOrder !== null && nextOrder.point.latitude && nextOrder.point.longitude) {
                                            //             latlngs.push([nextOrder.point.latitude, nextOrder.point.longitude])
                                            //         }
                                            //     }

                                            //     let p = L.polyline(latlngs, {
                                            //         color: '#444',
                                            //         weight: 2,
                                            //         dashArray: '10, 10',
                                            //         dashOffset: '0',
                                            //     })
                                            //     p.arrowheads({
                                            //         yawn: 25,
                                            //         size: '20px',
                                            //         fill: true,
                                            //         stroke: false,
                                            //     })
                                            //     this.polylines.push(p)
                                            //     p.addTo(this.map)
                                            //     p.getArrowheads().addTo(this.map)
                                            // }
                                        }
                                        i++
                                    }
                                    if (this.$store.state.showMarkers.couriers) {
                                        if (this.object_markers_created && this.couriersCount != this.couriers.length) {
                                            this.object_markers_created = false
                                            this.mapObjectsMarkersDelete(this.couriers)
                                        }

                                        this.couriersCount = this.couriers.length

                                        if (!this.object_markers_created) {
                                            this.object_markers_created = true
                                            this.mapObjectsMarkersCreate('couriers')
                                        }
                                        this.mapObjectsMarkersUpdate()
                                    } else {
                                        if (this.object_markers_created) {
                                            this.object_markers_created = false
                                            this.mapObjectsMarkersDelete(this.couriers)
                                        }
                                    }
                                })
                                .catch(err => {
                                    console.error(err)
                                })
                        }

                        if (this.timer) {
                            clearTimeout(this.timer)
                            this.timer = setTimeout(() => {
                                this.mapObjectsUpdate()
                            }, 5000)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        if (this.timer) {
                            clearTimeout(this.timer)
                            this.timer = setTimeout(() => {
                                this.mapObjectsUpdate()
                            }, 5000)
                        }
                    })
            },

            getDeviceType(type) {
                let res = '-'
                if (type == 7) {
                    res = 'ot2'
                } else if (type == 8) {
                    res = 'a5e'
                } else if (type == 14) {
                    res = 'tag'
                }
                return res
            },

            mapObjectsMarkersCreate(type = null) {
                let i = this.object_markers.length ? this.object_markers.length : 0
                // let arr = []
                // if (type != null) {
                //     if (this.$store.state.showMarkers.workers && type == 'workers') {
                //         arr = arr.concat(this.workers)
                //         console.log('WORKERS CREATED')
                //     }
                //     if (this.$store.state.showMarkers.couriers && type == 'couriers') {
                //         arr = arr.concat(this.couriers)
                //         console.log('COURIERS CREATED')
                //     }
                // } else {
                //     if (this.$store.state.showMarkers.workers) {
                //         arr = arr.concat(this.workers)
                //         console.log('WORKERS CREATED 2')
                //     }
                //     if (this.$store.state.showMarkers.couriers) {
                //         arr = arr.concat(this.couriers)
                //         console.log('COURIERS CREATED 2')
                //     }
                // }

                for (let courier of this.couriers) {
                    const object = courier.object
                    var data = 0
                    try {
                        data = object.data
                    } catch (e) {
                        data = []
                    }

                    if (data) {
                        if (!data.gspe) data.gspe = 0
                        if (!data.pext) data.pext = 0
                        if (!data.gsat) data.gsat = 0
                        let no_coord = false
                        if (!data.glat) {
                            data.glat = 43.073378
                            no_coord = true
                        }
                        if (!data.glng) {
                            data.glng = 131.98208
                            no_coord = true
                        }
                        if (!data.gcou) {
                            data.gcou = 110
                            no_coord = true
                        }

                        let icon = this.avatarIcon(courier)
                        let bat = data.bat ? data.bat.toFixed(0) : 0

                        if (object.device_type == 8) {
                            // A5E
                            bat *= 20
                        }

                        this.object_markers[i] = L.marker([data.glat, data.glng], {
                            icon,
                            zIndexOffset: 2000,
                            oid: object.oid,
                            ident: object.ident,
                            ordersActual: courier.ordersActual,
                            // multiOrdersActual: courier.multiOrdersActual,
                            free: courier.ordersActual.length === 0,
                            // free: courier.ordersActual.length + courier.multiOrdersActual.length === 0,
                            index: i,
                            courier: courier,
                            device_type: object.device_type,
                            speed: data.gspe.toFixed(0),
                            bat,
                            course: data.gcou,
                            online: object.online,
                            mqua: data.mqua,
                            sats: data.gsat,
                            popup_open: false,
                            popup_fix: false,
                            offset_orig: 2000,
                        }).addTo(this.map)

                        // Маркер - красная точка для определения места привязки значка курьера к координатам объекта
                        // L.marker([data.glat, data.glng], {
                        //     icon: new L.DivIcon({
                        //         html: '<div class="marker-pixel"></div>',
                        //         iconSize: 1,
                        //     }),
                        //     zIndexOffset: 5000,
                        // }).addTo(this.map)

                        this.object_markers[i]
                            .bindTooltip('', {
                                permanent: true,
                                direction: 'right',
                                offset: [32, -30],
                                zIndexOffset: 1000,
                                offset_orig: 1000,
                            })
                            .addTo(this.map)

                        this.object_markers[i].on('click', this.onDeviceMarkerMouseClick)
                        this.object_markers[i].on('mouseover', this.onDeviceMarkerMouseOver)
                        this.object_markers[i].on('mouseout', this.onDeviceMarkerMouseOut)

                        let this_ = this
                        this.object_markers[i].content = function () {
                            let content

                            // console.log('this_.couriers', this_.couriers)
                            // console.log('this.options.index', this.options.index)

                            content = '<big><u>' + this_.displayName(this.options.courier) + '</u></big>&nbsp;&nbsp;'
                            content += '<small>'

                            if (!this.options.ident) {
                                content += '<span class="label-color-red">нет трекера</span>'
                            } else if (!this.options.online) {
                                content += '<span class="label-color-red">нет связи</span>'
                            } else {
                                content += '<span class="label-color-green">на связи</span>'
                            }

                            content += '&nbsp;&nbsp;<span class="label-color-gray">[' + this_.getDeviceType(this.options.device_type) + ']</span>'

                            content += '</small><br>'

                            content += '<small><span class="label-color-grey">Скорость: ' + this.options.speed + ' км/ч</span></small><br>'
                            if (this.options.bat >= 0) {
                                content += '<small><span class="label-color-grey">Батарея: ' + this.options.bat + '%</span></small><br>'
                            }

                            content += '<br>'

                            // console.log('try options.orders 3')
                            // if (this.options.user.role == config.user.role.worker) {
                            //     if (this.options.free) content += '✅ Свободен<br>'
                            //     else content += `🛠 Выполняет заказ<br>`
                            // } else {
                            if (this.options.ordersActual.length == 0) {
                            // if (this.options.ordersActual.length + this.options.multiOrdersActual.length == 0) {
                                content += '✅ Свободен<br>'
                            } else {
                                content += `📦 Доставляет (${this.options.ordersActual.length}):<br>`
                                // content += `📦 Доставляет (${this.options.ordersActual.length + this.options.multiOrdersActual.length}):<br>`
                                content += `<div class="popup-orders">`
                                for (let order of this.options.ordersActual) {
                                    content += `<div class="popup-order-status"><div style="background-color: ${this_.orderStatusColorHtml1(order.status)}" class="item-order">${this_.getOrderName(order)}</div></div>`
                                }
                                content += '</div>'

                                // for (const multiOrder of this.options.multiOrdersActual) {
                                //     content += `<div class="popup-order-status"><div style="background-color: ${this_.orderStatusColorHtml1(multiOrder.status, true)}" class="item-order">${multiOrder.orders.map(e => this_.getOrderName(e)).join(' / ')}</div></div>`
                                // }
                                content += '</div>'
                            }
                            // }

                            content += '<br>'

                            let t1 = moment().toDate()
                            let t2 = moment(this.options.stime).toDate()
                            let dt = Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / 1000)
                            let sec_str
                            if (!this.options.ident) {
                                sec_str = 'местоположение недоступно'
                            } else {
                                if (isNaN(dt)) {
                                    sec_str = 'нет данных о местоположении'
                                } else {
                                    sec_str = 'местоположение получено ' + this_.getSecondsStr(dt, false) + ' назад'
                                }
                            }
                            content += '<small><span class="label-color-grey">' + sec_str + '</span></small><br>'
                            return content
                        }
                    }
                    i++
                }
                // this.map.setView([this.objects[0].data.glat, this.objects[0].data.glng], 18);
            },

            mapObjectsMarkersDelete(couriers = null) {
                console.log('DELETE couriers', couriers)
                if (this.map) {
                    let markers = Array.from(this.object_markers)
                    for (let object_marker of markers) {
                        if(this.marker_opened && this.marker_opened.options.index == object_marker.options.index){
                            this.popup_close(this.marker_opened, true)
                        }
                        if (object_marker && couriers && couriers.filter(courier => courier.id == object_marker.options.courier.id).length) {
                            try {
                                this.object_markers = this.object_markers.filter(marker => marker.options.courier.id != object_marker.options.courier.id)
                                this.map.removeLayer(object_marker)
                            } catch (e) {
                                console.log('ERROR mapObjectsMarkersDelete', object_marker, e.name, e.message)
                            }
                        } else if(object_marker && couriers === null){
                            try {
                                this.object_markers = this.object_markers.filter(marker => marker.options.courier.id != object_marker.options.courier.id)
                                this.map.removeLayer(object_marker)
                            } catch (e) {
                                console.log('ERROR mapObjectsMarkersDelete', object_marker, e.name, e.message)
                            }
                        }
                    }
                }
                if (couriers === null) {
                    this.object_markers = []
                }
            },

            mapObjectsMarkersUpdate() {
                let object_index = -1
                // let executors = []
                if (this.object_selected) {
                    object_index = this.couriers.findIndex(courier => courier.object?.oid == this.object_selected)
                    // if (object_index == -1) {
                    //     object_index = this.workers.findIndex(courier => courier.object?.oid == this.object_selected)
                    // }
                }
                // if (this.$store.state.showMarkers.workers) {
                //     executors = executors.concat(this.workers)
                // }
                // if (this.$store.state.showMarkers.couriers) {
                //     executors = executors.concat(this.couriers)
                // }

                let i = 0
                let ids = []
                for (let courier of this.couriers) {
                    const object = courier.object
                    let offset = 2000
                    if (i == object_index) offset = 3000
                    let data = 0
                    try {
                        data = object.data
                    } catch (e) {
                        console.log('ERROR X', e.name, e.message)
                    }
                    i++
                    const object_marker = this.object_markers.find(marker => marker.options.courier.id === courier.id)
                    ids.push(courier.id)
                    if (!data || !data.glat || !data.glng) {
                        console.log('skip data', courier.user.lastName)
                        continue
                    }

                    if (data) {
                        let marker = object_marker

                        if (marker) {
                            if (!data.gspe) data.gspe = 0
                            if (!data.pext) data.pext = 0
                            if (!data.gsat) data.gsat = 0
                            if (!data.gcou) data.gcou = 0
                            {
                                if (!data.glat) {
                                    data.glat = 43.073378
                                }
                                if (!data.glng) {
                                    data.glng = 131.98208
                                }
                                if (!data.gcou) {
                                    data.gcou = 110
                                }
                            }
                      
                            marker.options.speed = data.gspe.toFixed(0)
                            marker.options.bat = data.bat ? data.bat.toFixed(0) : 0
                            if (object.device_type == 8) {
                                // A5E
                                marker.options.bat *= 20
                            }

                            // console.log('object', object)

                            marker.setLatLng([data.glat, data.glng])

                            if (this.marker_opened != marker) marker.setZIndexOffset(offset)
                            marker.options.pext = data.pext.toFixed(1)
                            marker.options.sats = data.gsat
                            marker.options.course = data.gcou
                            marker.options.online = object.online
                            marker.options.mqua = data.mqua
                            marker.options.offset_orig = offset
                            marker.options.stime = object.stime
                            marker.options.ordersActual = courier.ordersActual
                            // marker.options.multiOrdersActual = courier.multiOrdersActual
                        }

                        let t1 = moment().toDate()
                        let t2 = moment(object.stime).toDate()
                        let dt = Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / 1000)
                        let cl = 'status-color-green'
                        if (!object.ident) {
                            cl = 'status-color-grey'
                        } else if (!object.online || (object.ping > 0 && dt > object.ping * 2)) {
                            cl = 'status-color-red'
                        }
                        let name = `<div class="status ${cl}"></div><span class="label-name">${this.displayName(courier)}</span>`
                        if (marker) {
                            marker._icon.innerHTML = this.avatarIconContent(courier)
                            marker.setTooltipContent(name)
                            marker.update()
                        }
                        if (marker == this.marker_opened && marker != null) {
                            this.popup.setContent(marker.content())
                            this.popup.setLatLng([data.glat, data.glng])
                            this.popup.update()
                        }
                    }
                }

                if (i !== this.object_markers.length){         
                    this.object_markers = this.object_markers.filter(marker => {
                        if (!ids.includes(marker.options.courier.id)) {
                            this.map.removeLayer(marker)
                        } else {
                            return marker
                        }
                    })
                }
            },

            onMapMouseClick(e) {
                if (e.originalEvent.target.nodeName !== 'path') {
                    if (this.marker_opened) {
                        this.popup_close(this.marker_opened, true)
                    }
                }
            },

            // События с попапами (открытие и закрытие)
            onMapPopupClose(e) {
                // Событие закрытия отрытого попапа на карте (e)
                if (!this.marker_over) {
                    if (this.marker_opened) this.popup_close(this.marker_opened, false)
                }
            },

            onMapPopupOpen(e) {
                this.marker_with_popup = this.marker_opened
            },

            popup_open(marker, autopan, offsety) {
                let option
                if (autopan) {
                    option = {
                        offset: [0, -offsety],
                        autoPanPaddingTopLeft: [50, 90],
                        autoPanPaddingBottomRight: [50, 0],
                        closeButton: false,
                        closeOnClick: false,
                    }
                } else {
                    option = {
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                    }
                }
                this.popup = L.popup(option).setLatLng(marker.getLatLng()).setContent(marker.content()).openOn(this.map)

                marker.options.popup_open = true
                marker.options.popup_fix = false
                this.marker_opened = marker
                this.object_selected = marker.options.oid
                if (marker.setZIndexOffset) marker.setZIndexOffset(5000)
            },

            popup_close(marker, popup_close) {
                if (marker) {
                    // if (marker.options.micon) marker.setIcon(marker.options.micon)
                    if (popup_close) this.map.closePopup(this.popup)
                    marker.options.popup_open = false
                    marker.options.popup_fix = false
                    if (marker.setZIndexOffset) marker.setZIndexOffset(marker.options.offset_orig)
                    this.marker_opened = null
                    this.object_selected = 0
                }
            },

            popup_fix_change(marker, offsety) {
                if (marker.options.micon) {
                    offsety = marker.options.icon.options.iconAnchor[1] + 1
                    if (marker.options.rotationAngle && marker.options.rotationAngle == 180) offsety = 6
                }
                if (marker.options.popup_fix) {
                    // Попап закреплен - убираем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                    })
                        .setLatLng(marker.getLatLng())
                        .setContent(marker.content())
                        .openOn(this.map)
                    marker.options.popup_fix = false
                } else {
                    // Попап не закреплен - устанавливаем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPanPaddingTopLeft: [50, 90],
                        autoPanPaddingBottomRight: [50, 0],
                        closeButton: true,
                        closeOnClick: false,
                    })
                        .setLatLng(marker.getLatLng())
                        .setContent(marker.content())
                        .openOn(this.map)
                    marker.options.popup_fix = true
                }
            },

            // События с маркерами объектов
            onDeviceMarkerMouseClick(e) {
                // console.log('onDeviceMarkerMouseClick', e)

                if (e.target.options.oid) {
                    let index = e.target.options.index
                    let object_index = this.couriers.findIndex(courier => courier.object.oid == this.object_selected)

                    if (index != object_index) {
                        this.popup_close(this.marker_opened, true)
                    }
                    var offsety = 56
                    if (this.marker_over) {
                        if (this.marker_opened && e.target == this.marker_opened) this.popup_fix_change(e.target, offsety)
                        else {
                            this.popup_close(this.marker_opened, true)
                            this.popup_open(e.target, true, offsety)
                            this.popup_fix_change(e.target, offsety)
                        }
                    } else {
                        // Only for tap of touch
                        if (this.marker_opened && e.target == this.marker_opened) this.popup_close(this.marker_opened, true)
                        else this.popup_open(e.target, true, offsety)
                    }
                }
            },

            onDeviceMarkerMouseOver(e) {
                if (!this.marker_over) {
                    this.marker_over = true
                }
                if (e.target.options.popup_open) {
                    // Наводим на маркер с открытым попапом
                } else {
                    var offsety = 56
                    // Наводим на маркер с закрытым попапом
                    if (!this.marker_opened || this.marker_opened.options.popup_fix == false) {
                        // если не зафиксирован открытый попап - открываем попап без кнопки закрытия
                        if (this.marker_opened) this.popup_close(this.marker_opened, true)
                        this.popup_open(e.target, false, offsety)
                    }
                }
            },

            onDeviceMarkerMouseOut(e) {
                if (e.target.options.popup_open) {
                    // Убираем указатель мыши с маркера с открытым попапом
                    if (!e.target.options.popup_fix) {
                        // Попап не зафиксирован - закрываем его попап и убираем фиксацию
                        this.popup_close(e.target, true)
                    }
                    if (this.marker_over) {
                        this.marker_over = false
                    }
                }
            },
        },
    }
</script>

<style lang="stylus">

    .expansion-panel-content
        max-height 240px
        overflow-y auto
        margin-bottom 20px

    .logistic
        height 100%

        #map
          width 100%
          height 100%
          z-index 1

        .map-wrapper
            width 100%
            height 100%
            position absolute
            display flex

        .fab-menu
            margin-bottom 5px

        .card-wrapper
            position absolute
            top 10px
            right 20px
            max-width 420px
            display flex
            flex-direction column
            align-items flex-end
            z-index 1

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor pointer
                padding 0 6px


        .status
            width 6px
            height 6px
            display inline-block
            margin-right 4px
            margin-bottom 2px

        .status-color-red
            background-color red

        .status-color-green
            background-color green

        .status-color-grey
            background-color grey

        .label-color-red
            color red

        .label-color-green
            color green

        .label-color-grey
            color grey

        .leaflet-div-icon
            border none
            background none

        .popup-orders
            display flex
            flex-direction column

        .popup-order-status
            display flex
            padding 2px 0

        .order-id-status
            display initial
            position initial
            border-radius 2px
            padding 0px 2px
            margin 0
            margin-right 6px
            color white
            font-size 12px
            font-weight 600
            box-shadow 1px 1px 1px grey
            cursor default

        .marker-pixel
            width: 1px
            height: 1px
            background-color: red

        .avatar-icon
            width 42px
            height 42px
            top -41px
            left -14px
            position absolute
            border-radius 50%

        .avatar-disabled
            filter brightness(300%)
            filter sepia(100%)
            filter gray
            filter blur(0.5em)

        .avatar-shadow
            top -44px
            left -21px
            position absolute

        .avatar-status
            width 16px
            height 16px
            border-radius 50%
            top -42px
            position absolute
            border solid 1px white
            box-shadow 0 0 1px black

        .label-name
            font-weight bold
            font-size 14px !important

        .leaflet-tooltip
            background-color rgba(220, 220, 220,0.7)
            color #333
            box-shadow none
            margin 0 0px
            padding 0
            border none
            border-radius 2px
            font-size 12px
            padding 1px 3px
            white-space nowrap
            text-shadow 1px 1px 2px white, -1px -1px 1px white
            z-index 1
            font-weight normal

        .leaflet-tooltip-top::before, .leaflet-tooltip-bottom::before, .leaflet-tooltip-left::before, .leaflet-tooltip-right::before
            display none

        .leaflet-tooltip-pane
            z-index 550

        .leaflet-popup-content-wrapper
            border-radius 4px
            font-size 13px

        .leaflet-popup-content
            margin 12px 14px

        .item-courier
            color #333
            cursor pointer
            font-size 14px
            font-weight 500
            padding 5px
            margin 0
            &:hover
                background-color #f0f0f0

        .item-courier-empty
            color #aaa
            cursor default


    .arrow-icon {
        width: 18px;
        height: 18px;
        opacity 0.8
        // z-index 0
    }

    .arrow-icon > div {
        // opacity 0.6
        margin-left: -4px
        margin-top: -4px;
        transform-origin: center center;
        font: 18px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
</style>
